import React, {useEffect, useRef} from 'react';
import { observer } from 'mobx-react-lite';
import tabStore from '../stores/TabStore'; // Предполагается, что у вас есть хранилище MobX для табов
import CategoryList from './Category_0_List';
import {useNotification} from "./shared/notify";
import getImgUrl from "./shared/getImgUrl";

const TabList = observer(() => {
    useEffect(() => {
        tabStore.fetchTabs();
    }, []);

    const showNotification = useNotification;
    let inputRef = useRef(null);

    if (tabStore.loading) { return <p>Loading...</p>; }
    if (tabStore.error) { return <p>Error fetching tabs: {tabStore.error.message}</p>; }

    const handleAddTab = async () => {
        const name = prompt("Enter tab name:");
        if (name) {
            const sortParameter = tabStore.tabs.length;
            try {
                await tabStore.addTab(name, sortParameter);
                showNotification('Tab created successfully', 'success');
            } catch (error) {
                console.error('Error creating tab:', error);
                showNotification('An error occurred while creating the tab. Please try again.', 'error');
            }
        }
    };

    const handleEditTab = async (tabIndex) => {
        const tab = tabStore.tabs[tabIndex];
        const newName = prompt("Enter new tab name:", tab.name);
        if (newName && newName !== tab.name) {
            try {
                await tabStore.editTab(tabIndex, {name:newName});
                showNotification('Tab updated successfully', 'success');
            } catch (error) {
                console.error('Error updating tab:', error);
                showNotification('An error occurred while updating the tab. Please try again.', 'error');
            }
        }
    };

    const handleDeleteTab = async (tabIndex) => {
        const tab = tabStore.tabs[tabIndex];
        if (confirm(`Are you sure you want to delete the tab "${tab.name}"?`)) {
            try {
                await tabStore.deleteTab(tabIndex);
                showNotification('Tab deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting tab:', error);
                showNotification('An error occurred while deleting the tab. Please try again.', 'error');
            }
        }
    };

    const handleAddCategory = async (tabIndex) => {
        const categoryName = prompt("Enter category name:");
        if (categoryName) {
            let categoryDescription = prompt("Enter category description:");
            if(!categoryDescription) categoryDescription = '-'
            try {
                await tabStore.addCategory(tabIndex, categoryName, categoryDescription);
                showNotification('Category created successfully', 'success');
            } catch (error) {
                console.error('Error creating category:', error);
                showNotification('An error occurred while creating the category. Please try again.', 'error');
            }
        }
    };
    const openFileDialog = (i) => {
        inputRef.current.dataset.index = i;
        inputRef.current.click();
    }
    const updateTabImg = async (event) => {
        const tabIndex = inputRef.current.dataset.index;
        const imgFile = event.target.files[0];
        if (imgFile) {
            try {
                await tabStore.editTab(tabIndex, {imageFile:imgFile});
                showNotification('Tab updated successfully', 'success');
            } catch (error) {
                console.error('Error updating tab:', error);
                showNotification('An error occurred while updating the tab. Please try again.', 'error');
            }
        }
    };

    return (
        <div className="tab-structure">
            <ul className="tab-list">
                <input type="file" ref={inputRef} onChange={updateTabImg} style={{display: 'none'}}/>
                {tabStore.tabs.length === 0 ? (
                    <p>No tabs available. Click "Add New Tab" to create one.</p>
                ) : (
                    tabStore.tabs.map((tab, tabIndex) => (
                        <li key={tabIndex} className="nested-item tab-item">
                            <div className="tab-header" data-id={`tab-${tabIndex}`}>
                                <div className={"tab-thumbnail-container"}>
                                    <img src={getImgUrl(tab.imgUrl)} alt={tab.name} className="tab-thumbnail" />
                                    <button className="edit-tab-img-btn" data-tab={tabIndex} title="Edit Image" onClick={()=>openFileDialog(tabIndex)}>
                                        <i className="fas fa-upload"></i>
                                    </button>
                                </div>
                                <span className="tab-name">{tab.name}</span>
                                <span>
                  <button className="edit-tab-btn" data-tab={tabIndex} title="Edit Tab" onClick={() => handleEditTab(tabIndex)}>
                    <i className="fas fa-pencil-alt"></i>
                  </button>
                  <button className="delete-tab-btn" data-tab={tabIndex} title="Delete Tab" onClick={() => handleDeleteTab(tabIndex)}>
                    <i className="fas fa-trash-alt"></i>
                  </button>
                  <button className="add-category-btn" data-tab={tabIndex} onClick={() => handleAddCategory(tabIndex)}>
                    <i className="fas fa-plus"></i> Add Category
                  </button>
                </span>
                            </div>
                            <CategoryList categories={tab.categories} tabIndex={tabIndex} mainCategoryIndex={tab.mainCategoryId}/>
                        </li>
                    ))
                )}
            </ul>
            <button className="add-tab-btn" onClick={handleAddTab}>
                Add New Tab
            </button>
        </div>
    );
});

export default TabList;
