import {API_BASE_URL} from "../constants";
import axios from 'axios';



class ApiService {
    constructor() {
        this.baseUrl = API_BASE_URL;
        this.authToken = localStorage.getItem('authToken') || null;
    }

    setAuthToken(token) {
        this.authToken = token;
    }

    async login(email, password) {
        const response = await fetch(`${this.baseUrl}/auth/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        });
        if (!response.ok) throw new Error('Login failed');
        return response.json();
    }

   async fetchTabs(pagination) {
        //const params = encodeURIComponent(JSON.stringify(pagination));
        try {
            const response = await axios.get(`${this.baseUrl}/tabs`,{
                headers: {
                    'Authorization': `Bearer ${this.authToken}`
                },
                params:pagination
            });
            const data = response.data;

            //this.showNotification('Tabs fetched successfully', 'success');
            return data;
        } catch (error) {
            console.error('Error fetching tabs:', error);
            //this.showNotification('Error fetching tabs', 'error');
            throw error;
        }
    }


    async createCategory(tabId, categoryName, categoryDescription, sortParameter) {
        const formData = new FormData();
        formData.append("name", categoryName);
        formData.append("description", categoryDescription);
        formData.append("sortParameter", sortParameter.toString());
        formData.append("tabId", tabId);

        try {
            const response = await fetch(`${this.baseUrl}/categories`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${this.authToken}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            //this.showNotification('Category created successfully', 'success');
            return data;
        } catch (error) {
            console.error('Error creating category:', error);
            //this.showNotification('Error creating category', 'error');
            throw error;
        }
    }

    async createSound(name, duration, isPremium, categoryIds, sortParameter, soundFile, imgFile) {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("duration", duration);
        formData.append("isPremium", isPremium);
        categoryIds.forEach((id, index) => {
            formData.append(`categoryIds[${index}]`, id);
        });
        formData.append("sortParameter", sortParameter);
        if (soundFile) {
            formData.append("soundFile", soundFile, soundFile.name);
        }
        if (imgFile) {
            formData.append("imgFile", imgFile, imgFile.name);
        }
        try {
            const response = await fetch(`${this.baseUrl}/sounds/with-categories`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${this.authToken}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            //this.showNotification('Sound created successfully', 'success');
            return data;
        } catch (error) {
            console.error('Error creating sound:', error);
            //this.showNotification('Error creating sound', 'error');
            throw error;
        }
    }

    async saveData(data) {
        try {
            const response = await fetch(`${this.baseUrl}/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.authToken}`
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            //this.showNotification('Data saved successfully', 'success');
            return result;
        } catch (error) {
            console.error('Error saving data:', error);
            //this.showNotification('Error saving data', 'error');
            throw error;
        }
    }

    async updateCategory(categoryId, name, description, sortParameter, imageFile, imgUrl) {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("sortParameter", sortParameter.toString());
        if (imageFile) {
            formData.append("imageFile", imageFile);
        }
        if (imgUrl) {
            formData.append("imgUrl", imgUrl);
        }

        try {
            const response = await fetch(`${this.baseUrl}/categories/${categoryId}`, {
                method: 'PUT',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${this.authToken}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            //this.showNotification('Category updated successfully', 'success');
            return data;
        } catch (error) {
            console.error('Error updating category:', error);
            //this.showNotification('Error updating category', 'error');
            throw error;
        }
    }

    async createTab(name, sortParameter, mainCategoryId, imageFile, imgUrl) {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("sortParameter", sortParameter.toString());
        if (mainCategoryId) formData.append("mainCategoryId", mainCategoryId);
        if (imageFile) formData.append("imageFile", imageFile);
        if (imgUrl) formData.append("imgUrl", imgUrl);

        try {
            const response = await fetch(`${this.baseUrl}/tabs`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${this.authToken}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            //this.showNotification('Tab created successfully', 'success');
            return data;
        } catch (error) {
            console.error('Error creating tab:', error);
            //this.showNotification('Error creating tab', 'error');
            throw error;
        }
    }

    async updateTab(tabId, name, sortParameter, mainCategoryId, imageFile, imgUrl) {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("sortParameter", sortParameter.toString());
        if (mainCategoryId) formData.append("mainCategoryId", mainCategoryId);
        if (imageFile) formData.append("imageFile", imageFile);
        if (imgUrl) formData.append("imgUrl", imgUrl);

        try {
            const response = await fetch(`${this.baseUrl}/tabs/${tabId}`, {
                method: 'PUT',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${this.authToken}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            //this.showNotification('Tab updated successfully', 'success');
            return data;
        } catch (error) {
            console.error('Error updating tab:', error);
            //this.showNotification('Error updating tab', 'error');
            throw error;
        }
    }

    async updateSound(soundId, name, duration, imgUrl = '', soundUrl = '', isPremium, sortParameter, imageFile = null) {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("duration", duration);
        formData.append("imgUrl", imgUrl);
        formData.append("soundUrl", soundUrl);
        formData.append("isPremium", isPremium.toString());
        formData.append("sortParameter", sortParameter);
        if (imageFile) {
            formData.append("imageFile", imageFile, imageFile.name);
        }
        try {
            const response = await fetch(`${this.baseUrl}/sounds/${soundId}`, {
                method: 'PUT',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${this.authToken}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            //this.showNotification('Sound updated successfully', 'success');
            return data;
        } catch (error) {
            console.error('Error updating sound:', error);
            //this.showNotification('Error updating sound', 'error');
            throw error;
        }
    }


    async deleteTab(tabId) {
        try {
            const response = await fetch(`${this.baseUrl}/tabs/${tabId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${this.authToken}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            //this.showNotification('Tab deleted successfully', 'success');
            return data;
        } catch (error) {
            console.error('Error deleting tab:', error);
            //this.showNotification('Error deleting tab', 'error');
            throw error;
        }
    }

    async deleteCategory(categoryId) {
        try {
            const response = await fetch(`${this.baseUrl}/categories/${categoryId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${this.authToken}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            //this.showNotification('Category deleted successfully', 'success');
        } catch (error) {
            console.error('Error deleting category:', error);
            //this.showNotification('Error deleting category', 'error');
            throw error;
        }
    }

    async deleteSound(soundId) {
        try {
            const response = await fetch(`${this.baseUrl}/sounds/${soundId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${this.authToken}`
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            //this.showNotification('Sound deleted successfully', 'success');
        } catch (error) {
            console.error('Error deleting sound:', error);
            //this.showNotification('Error deleting sound', 'error');
            throw error;
        }
    }
}

const apiService = new ApiService();
export { apiService };
