import {action, makeAutoObservable, observable} from "mobx";
import { apiService } from "../services/apiService";

class TabStore {
    tabs = [];
    loading = false;
    error = null;
    imgImportExportUrl = '';

    constructor() {
        makeAutoObservable(this/*, {
            tabs: observable,
            loading: observable,
            error: observable,
            fetchTabs: action,
            addTab: action,
            editTab: action,
            deleteTab: action,
            addCategory: action,
            updateCategory: action,
            deleteCategory: action,
            addSound: action
        }*/);
    }

    setSoundImgExport(linkToSet = ''){
        this.imgImportExportUrl = linkToSet;
    }

    async fetchTabs() {
        this.loading = true;
        const pagination = {
            "categories": {
                "limit": 1000,
                "offset": 0
            },
            "sounds": {
                "limit": 1000,
                "offset": 0
            }
        };
        try {
            this.tabs = await apiService.fetchTabs(pagination);
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error = error;
        }
    }

    /********
     * TABS F
     */
    async addTab(name, sortParameter) {
        try {
            const newTab = await apiService.createTab(name, sortParameter);
            newTab.categories = [];
            this.tabs.push(newTab);
            return newTab;
        } catch (error) {
            this.error = error;
            throw error;
        }
    }
    async editTab(tabIndex, updates) {
        const tab = this.tabs[tabIndex];
        try {
            const updatedTab = await apiService.updateTab(
                tab._id,
                updates.name || tab.name,
                updates.sortParameter || tab.sortParameter,
                updates.mainCategoryId || tab.mainCategoryId,
                updates.imageFile || null,
                updates.imgUrl || tab.imgUrl,
            );
            this.tabs[tabIndex] = { ...tab, ...updatedTab };
            return updatedTab;
        } catch (error) {
            this.error = error;
            throw error;
        }
    }
    async deleteTab(tabIndex) {
        const tab = this.tabs[tabIndex];
        try {
            await apiService.deleteTab(tab._id);
            this.tabs.splice(tabIndex, 1);
            return tab;
        } catch (error) {
            this.error = error;
            throw error;
        }
    }


    /********
     * CATEGORIES F
     */
    async addCategory(tabIndex, categoryName, categoryDescription) {
        let tab = this.tabs[tabIndex];
        const sortParameter = tab.categories.length;
        try {
            const newCategory = await apiService.createCategory(tab._id, categoryName, categoryDescription, sortParameter);
            tab.categories.push({...newCategory, sounds: observable([])});

            console.log('addCategory')
            return newCategory;
        } catch (error) {
            this.error = error;
            throw error;
        }
    }
    async updateCategory(tabIndex, categoryIndex, updates) {
        const category = this.tabs[tabIndex].categories[categoryIndex];
        try {
            const updatedCategory = await apiService.updateCategory(
                category._id,
                updates.name || category.name,
                updates.description || category.description,
                updates.sortParameter || category.sortParameter,
                updates.imageFile || category.imageFile,
                updates.imgUrl || category.imgUrl,
            );
            this.tabs[tabIndex].categories[categoryIndex] = {
                ...category,
                ...updatedCategory
            };
            return updatedCategory;
        } catch (error) {
            this.error = error;
            throw error;
        }
    }
    async deleteCategory(tabIndex, categoryIndex) {
        const category = this.tabs[tabIndex].categories[categoryIndex];
        try {
            await apiService.deleteCategory(category._id);
            this.tabs[tabIndex].categories.splice(categoryIndex, 1);
            return category;
        } catch (error) {
            this.error = error;
            throw error;
        }
    }

    /********
     * SOUNDS F
     */
    async addSound(tabIndex, categoryIndex, fileName, soundFile, imgFile, placeholderSound) {
        const category = this.tabs[tabIndex].categories[categoryIndex];
        //const indexSound = category.sounds.push(placeholderSound) - 1;

        try {
            // Создаём промис для получения длительности
            const getDuration = () => {
                return new Promise((resolve, reject) => {
                    const audio = new Audio();
                    audio.src = URL.createObjectURL(soundFile);
                    audio.onloadedmetadata = () => {
                        const duration = Math.round(audio.duration / 60);
                        resolve(duration);
                    };
                    audio.onerror = () => {
                        reject(new Error('Failed to load audio file'));
                    };
                });
            };

            // Дожидаемся разрешения промиса и получения длительности
            const duration = await getDuration();

            const newSound = await apiService.createSound(
                fileName,
                duration,
                false, // Default isPremium value
                [category._id],
                category.sounds.length,
                soundFile,
                imgFile
            );
            //category.sounds[indexSound] = newSound;
            console.log('newSound', newSound)
            return newSound;
        } catch (error) {
            //category.sounds = category.sounds.filter(sound => sound !== placeholderSound);
            this.error = error;
            throw error;
        }
    }
    async deleteSound(tabIndex, categoryIndex, soundIndex) {
        const category = this.tabs[tabIndex].categories[categoryIndex];
        const sound = category.sounds[soundIndex];
        try {
            await apiService.deleteSound(sound._id);
            category.sounds.splice(soundIndex, 1);
            return sound;
        } catch (error) {
            this.error = error;
            throw error;
        }
    }
    async updateSound(tabIndex, categoryIndex, soundIndex, updates) {
        const category = this.tabs[tabIndex].categories[categoryIndex];
        const sound = category.sounds[soundIndex];
        try {
            const updatedSound = await apiService.updateSound(
                sound._id,
                updates.name || sound.name,
                updates.duration || sound.duration,
                updates.imgUrl || sound.imgUrl,
                updates.soundUrl || sound.soundUrl,
                updates.isPremium !== undefined ? updates.isPremium : sound.isPremium,
                updates.sortParameter || sound.sortParameter,
                updates.imageFile || null
            );
            //category.sounds[soundIndex] = { ...category.sounds[soundIndex], ...updates, _id: updatedSound._id };
            category.sounds[soundIndex] = updatedSound;
            return updatedSound;
        } catch (error) {
            this.error = error;
            throw error;
        }
    }

}

const tabStore = new TabStore();
export default tabStore;
